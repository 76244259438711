import React, { Component } from 'react';
import DineInScannerView from './DineInScannerView';
import TakeawayScannerView from './TakeawayScannerView';
import IdleScannerView from './IdleScannerView';
import WarmerScannerView from './WarmerScannerView';
import BagScannerView from './BagScannerView';
import BagScannerLiveDispatchView from './BagScannerLiveDispatchView';
import BarcodeReader from 'react-barcode-reader';
import Loading from '../Loading';
import InstaserviceScannerView from './InstaserviceScannerView';
import QrReader from 'react-qr-reader';

const base = 'dispatch_scan';

const DINE_IN = 'DINE_IN';
const ON_ROUTE = 'ON_ROUTE';
const TAKE_AWAY = 'TAKE_AWAY';
const IN_WARMER = 'IN_WARMER';
const PRINTING_FAILED = 'Printing Failed';

class ScanOverview extends Component {
    handleError = (err) => {
        console.error(err);
    };

    render() {
        const {
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            handleScan,
            loading,
            itemImage,
            originalItemImageLink,
            show,
        } = this.props;
        const printingStatus = 'Printing sticker..';
        if (loading) {
            return <Loading />;
        }
        return (
            <div className={`${base} mxl`}>
                <div className={`${base}__header`}>
                    <div
                        className={`${base}__header_left width--33 display--inline-block mtm plm`}
                    >
                        Dispatch Intake Scanner
                    </div>
                    <div
                        className={`${base}__header_center width--33 display--inline-block mtm`}
                    >
                        {scannedItem &&
                        scannedItem.item &&
                        scannedItem.item.status === ON_ROUTE &&
                        scannedItem.dispatch_no &&
                        scannedItem.route_no
                            ? `Dispatch ${scannedItem.dispatch_no} - Route ${scannedItem.route_no}`
                            : ''}
                    </div>
                    <div
                        className={`${base}__header_right width--33 display--inline-block mtm prm`}
                    >
                        {printingStatus}
                    </div>
                </div>

                {!scannedItem && (
                    <div className={`bag_scan__qr-reader`}>
                            <div className={`bag_scan__scan-text`}> Scan the bag's QR code </div>
                            <QrReader
                                delay={500}
                                onError={this.handleError}
                                onScan={handleScan}
                                facingMode="environment"
                            />
                    </div>
                )}

                <BarcodeReader onError={this.handleError} onScan={handleScan} />
                {scannedItem &&
                scannedItem.isWarmerItem &&
                scannedItemError.length === 0 &&
                show ? (
                    <InstaserviceScannerView
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                scannedItem.pick_up_type === DINE_IN &&
                scannedItemError.length === 0 &&
                show ? (
                    <DineInScannerView
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                scannedItem.pick_up_type === TAKE_AWAY &&
                scannedItemError.length === 0 &&
                show ? (
                    <TakeawayScannerView
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                scannedItem.item &&
                scannedItem.item.status === ON_ROUTE &&
                printingStatus !== PRINTING_FAILED &&
                scannedItemError.length === 0 &&
                show ? (
                    <BagScannerView
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                scannedItem.item &&
                scannedItem.item.status === IN_WARMER &&
                scannedItemError.length === 0 &&
                show ? (
                    <WarmerScannerView
                        scannedItemSKU={scannedItemSKU}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={itemImage}
                    />
                ) : scannedItem &&
                    scannedItem.bag &&
                    show ? (
                    <BagScannerLiveDispatchView
                        scannedItem={scannedItem}
                        itemImage={itemImage}
                    />
                ) : (
                    <IdleScannerView scannedItemError={scannedItemError} />
                )}
            </div>
        );
    }
}

export default ScanOverview;

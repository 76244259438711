import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Fullscreen from 'react-full-screen';
import {Helmet} from "react-helmet";
import { getFromLocalStorage } from '../../services/utils';
import Loading from '../Loading';
import OrderCategoryItems from './OrderCategoryItems';
import InOutletSubHeadingWrapper from './InOutletSubHeadingWrapper';
import DeliverySubHeadingWrapper from './DeliverySubHeadingWrapper';
import IncomingItems from './IncomingItems';
import InstaServiceProduction from './InstaServiceProduction';

const base = 'cooking-v2';
const ONE_SECOND = 1000;

@inject('OrdersKitchenStore', 'UserStore')
@observer
class CookingV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullscreen: false,
        };
    }

    componentDidMount() {
        const {
            OrdersKitchenStore: {
                handleGetDineInOrders,
                handleGetDeliveryOrders,
                getInCartOrders,
                handleGetInstaServiceProductions
            },
            UserStore,
        } = this.props;
        const {
            selectedHub: { isOnDemandProduction },
            handleSetFullscreen
        } = UserStore;
        let hubCode = getFromLocalStorage('hub').value;
        handleSetFullscreen(true)

        if (isOnDemandProduction) {
            handleGetDineInOrders(true);
            handleGetDeliveryOrders(true);
            getInCartOrders(hubCode);
            handleGetInstaServiceProductions(true);
            this.interval = setInterval(() => {
                handleGetDineInOrders(false);
                handleGetDeliveryOrders(false);
                getInCartOrders(hubCode);
                handleGetInstaServiceProductions(false);
            }, 10 * ONE_SECOND); // 10 seconds
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { OrdersKitchenStore, UserStore } = this.props;
        const {
            isCurrentPageFullScreen: isFullscreen,
            handleSetFullscreen,
            selectedHub: { isOnDemandProduction },
        } = UserStore;
        const {
            delieveryOrders,
            dineInOrders,
            delieveryOrdersLoading,
            dineInOrdersLoading,
            inCartItems,
            instaServiceProductions
        } = OrdersKitchenStore;

        const allOrders = [...dineInOrders, ...delieveryOrders]
            .filter((order) => order.status === 'Pending' && order.production_started_at)
            .sort((a, b) => a.order_date - b.order_date);

        const inOutletNasiNasiItems = allOrders.flatMap(order => 
            order.items.filter(item => 
                item.nasi_nasi_nasi_category && 
                item.status != 'ON_ROUTE' &&
                (item.pick_up_type == "DINE_IN" || item.pick_up_type == "TAKE_AWAY") && 
                !item.platform.includes('GrabFood') &&
                !item.platform.includes('FoodPanda') &&
                !item.platform.includes('ShopeeFood'))
        );

        const deliveryNasiNasiItems = allOrders.flatMap(order => 
            order.items.filter(item => (item.nasi_nasi_nasi_category && 
                item.status != 'ON_ROUTE' &&
                (item.pick_up_type == null ||
                    (item.pick_up_type != null &&
                        (item.platform.includes('GrabFood') ||
                        item.platform.includes('FoodPanda') ||
                        item.platform.includes('ShopeeFood'))))))
        );

        const incomingNasiNasiItems = inCartItems.filter(item => (item.nasi_nasi_nasi_category));

        const inOutletPastaItems = allOrders.flatMap(order => 
            order.items.filter(item => 
                (item.item_type == 'Item' &&
                item.today_menu_id != "CUTLERY" &&
                item.status != 'ON_ROUTE' &&
                !item.nasi_nasi_nasi_category && 
                (item.pick_up_type == "DINE_IN" || item.pick_up_type == "TAKE_AWAY") && 
                !item.platform.includes('GrabFood') &&
                !item.platform.includes('FoodPanda') &&
                !item.platform.includes('ShopeeFood')))
        );

        const deliveryPastaItems = allOrders.flatMap(order => 
            order.items.filter(item => 
                (item.item_type == 'Item' && 
                item.today_menu_id != "CUTLERY" &&
                item.status != 'ON_ROUTE' &&
                !item.nasi_nasi_nasi_category && 
                (item.pick_up_type == null ||
                    (item.pick_up_type != null &&
                        (item.platform.includes('GrabFood') ||
                        item.platform.includes('FoodPanda') ||
                        item.platform.includes('ShopeeFood'))))
                ))
        );

        const incomingPastaItems = inCartItems.filter(item => (item.item_type == 'Item' && 
            item.today_menu_id != "CUTLERY" && 
            !item.nasi_nasi_nasi_category));

        const inOutletSides = allOrders.flatMap(order => 
            order.items.filter(item => 
                (((item.item_type == "Addons" && item.category_name !== 'Drinks') ||
                    item.today_menu_id == "CUTLERY") &&
                item.status != 'ON_ROUTE' &&
                (item.pick_up_type == "DINE_IN" || item.pick_up_type == "TAKE_AWAY") && 
                !item.platform.includes('GrabFood') &&
                !item.platform.includes('FoodPanda') &&
                !item.platform.includes('ShopeeFood')))
        );

        const deliverySides = allOrders.flatMap(order => 
            order.items.filter(item => 
                (((item.item_type == "Addons" && item.category_name !== 'Drinks') ||
                    item.today_menu_id == "CUTLERY") &&
                item.status != 'ON_ROUTE' &&
                    (item.pick_up_type == null ||
                        (item.pick_up_type != null &&
                            (item.platform.includes('GrabFood') ||
                            item.platform.includes('FoodPanda') ||
                            item.platform.includes('ShopeeFood'))))))
        );

        const incomingSides = inCartItems.filter(item => ((item.item_type == "Addons" && 
            item.category_name !== 'Drinks') ||
            item.today_menu_id == "CUTLERY"));

        const inOuteltDrinks = allOrders.flatMap(order => 
            order.items.filter(item => 
                (item.category_name == 'Drinks' && 
                item.status != 'ON_ROUTE' &&
                (item.pick_up_type == "DINE_IN" || item.pick_up_type == "TAKE_AWAY") && 
                !item.platform.includes('GrabFood') &&
                !item.platform.includes('FoodPanda') &&
                !item.platform.includes('ShopeeFood')))
        );

        const deliveryDrinks = allOrders.flatMap(order => 
            order.items.filter(item => 
                (item.category_name == 'Drinks' && 
                item.status != 'ON_ROUTE' &&
                (item.pick_up_type == null ||
                    (item.pick_up_type != null &&
                        (item.platform.includes('GrabFood') ||
                        item.platform.includes('FoodPanda') ||
                        item.platform.includes('ShopeeFood'))))))
        );

        const incomingDrinks = inCartItems.filter(item => (item.category_name == 'Drinks'));

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => handleSetFullscreen(isFullscreen)}
            >
                <Helmet>
                    <title>Cooking V2 | Outlet OS | Pop Meals</title>
                </Helmet>
                {isOnDemandProduction && (
                    <div className={`${base} width--100`}>
                        {dineInOrdersLoading || delieveryOrdersLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <div style={{maxHeight: '100vh', overflow: 'scroll'}}>
                                    <div className={`${base}__main_bar`}>
                                        <div className='col-sm-3'>
                                            NASI
                                        </div>
                                        <div className='col-sm-3'>
                                            PASTA
                                        </div>
                                        <div className='col-sm-3'>
                                            SIDES
                                        </div>
                                        <div className='col-sm-3'>
                                            DRINKS
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            `${
                                                isFullscreen ? "col-sm-3 cooking-v2__item_column_full_screen" : "col-sm-3 cooking-v2__item_column"
                                            }`}
                                        style={
                                            (instaServiceProductions == null || instaServiceProductions.length == 0) ? {minHeight: '100vh'} : {}
                                        }
                                    >
                                        {((inOutletNasiNasiItems &&
                                            inOutletNasiNasiItems.length > 0) ||
                                            (incomingNasiNasiItems &&
                                                incomingNasiNasiItems.length > 0)) && (
                                                <InOutletSubHeadingWrapper
                                                    haveInOutletOrders = {inOutletNasiNasiItems && inOutletNasiNasiItems.length > 0}/>
                                            )
                                        }
                                        {inOutletNasiNasiItems &&
                                            inOutletNasiNasiItems.length > 0 &&
                                            inOutletNasiNasiItems.map((item) => (
                                                <OrderCategoryItems
                                                    item = {item}
                                                />
                                        ))}
                                        {incomingNasiNasiItems && incomingNasiNasiItems.length > 0 && (
                                            <IncomingItems items = {incomingNasiNasiItems} />
                                        )}
                                        {deliveryNasiNasiItems &&
                                        deliveryNasiNasiItems.length > 0  && (
                                            <DeliverySubHeadingWrapper />
                                            )
                                        }
                                        {deliveryNasiNasiItems &&
                                            deliveryNasiNasiItems.length > 0 &&
                                            deliveryNasiNasiItems.map((item) => (
                                                <OrderCategoryItems
                                                    item = {item}
                                                />
                                        ))}
                                    </div>
                                    <div
                                        className={
                                        `${
                                            isFullscreen ? "col-sm-3 cooking-v2__item_column_full_screen" : "col-sm-3 cooking-v2__item_column"
                                        }`}
                                    >
                                        {((inOutletPastaItems &&
                                            inOutletPastaItems.length > 0) ||
                                            (incomingPastaItems &&
                                                incomingPastaItems.length > 0)) && (
                                                <InOutletSubHeadingWrapper
                                                    haveInOutletOrders = {inOutletPastaItems && inOutletPastaItems.length > 0}/>
                                            )
                                        }
                                        {inOutletPastaItems &&
                                            inOutletPastaItems.length > 0 &&
                                            inOutletPastaItems.map((item) => (
                                                <OrderCategoryItems
                                                    item = {item}
                                                />
                                            ))}
                                        {incomingPastaItems && incomingPastaItems.length > 0 && (
                                            <IncomingItems items = {incomingPastaItems} />
                                        )}
                                        {deliveryPastaItems &&
                                        deliveryPastaItems.length > 0 && (
                                            <DeliverySubHeadingWrapper />
                                            )
                                        }
                                        {deliveryPastaItems &&
                                            deliveryPastaItems.length > 0 &&
                                            deliveryPastaItems.map((item) => (
                                                <OrderCategoryItems
                                                    item = {item}
                                                />
                                        ))}
                                    </div>
                                    <div
                                        className={
                                        `${
                                            isFullscreen ? "col-sm-3 cooking-v2__item_column_full_screen" : "col-sm-3 cooking-v2__item_column"
                                        }`}
                                    >
                                        {((inOutletSides &&
                                            inOutletSides.length > 0) ||
                                            (incomingSides &&
                                                incomingSides.length > 0)) && (
                                                <InOutletSubHeadingWrapper
                                                    haveInOutletOrders = {inOutletSides && inOutletSides.length > 0}/>
                                            )
                                        }
                                        {inOutletSides &&
                                            inOutletSides.length > 0 &&
                                            inOutletSides.map((item) => (
                                                <OrderCategoryItems
                                                    item = {item}
                                                />
                                        ))}
                                        {incomingSides && incomingSides.length > 0 && (
                                            <IncomingItems items = {incomingSides} />
                                        )}
                                        {deliverySides &&
                                        deliverySides.length > 0 && (
                                            <DeliverySubHeadingWrapper />
                                            )
                                        }
                                        {deliverySides &&
                                            deliverySides.length > 0 &&
                                            deliverySides.map((item) => (
                                                <OrderCategoryItems
                                                    item = {item}
                                                />
                                        ))}
                                    </div>
                                    <div
                                        className={
                                        `${
                                            isFullscreen ? "col-sm-3 cooking-v2__item_column_full_screen" : "col-sm-3 cooking-v2__item_column"
                                        }`}
                                    >
                                        {((inOuteltDrinks &&
                                            inOuteltDrinks.length > 0) ||
                                            (incomingDrinks &&
                                                incomingDrinks.length > 0)) && (
                                                <InOutletSubHeadingWrapper
                                                    haveInOutletOrders = {inOuteltDrinks && inOuteltDrinks.length > 0}/>
                                            )
                                        }
                                        {inOuteltDrinks &&
                                            inOuteltDrinks.length > 0 &&
                                            inOuteltDrinks.map((item) => (
                                                <OrderCategoryItems
                                                    item = {item}
                                                />
                                            ))}

                                        {incomingDrinks && incomingDrinks.length > 0 && (
                                            <IncomingItems items = {incomingDrinks} />
                                        )}
                                        {deliveryDrinks &&
                                        deliveryDrinks.length > 0 && (
                                            <DeliverySubHeadingWrapper />
                                        )
                                        }
                                        {deliveryDrinks &&
                                            deliveryDrinks.length > 0 &&
                                            deliveryDrinks.map((item) => (
                                                <OrderCategoryItems 
                                                    item = {item}
                                                />
                                        ))}
                                    </div>
                                </div>
                                {instaServiceProductions != null &&
                                instaServiceProductions.length > 0 && (
                                    <div className={`${base}__insta_service_production_container`}>
                                        <InstaServiceProduction
                                            instaServiceProductions={instaServiceProductions}
                                        />
                                    </div>

                                )}
                            </div>
                        )}
                    </div>
                )}
            </Fullscreen>
        );
    }
}

export default CookingV2;

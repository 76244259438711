import React, { Component, createRef } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
const base = 'secondary-header';

@inject('LiveDispatchStore', 'UserStore', 'RiderAndOutletPartnerStore')

@observer
class DispatchHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldScroll: false,
        };
        this.containerRef = createRef();
        this.scrollAreaRef = createRef();
    }


    componentDidMount() {
        this.storeInterval = setInterval(() => {
            const containerWidth = this.containerRef.current.offsetWidth;
            const scrollAreaWidth = this.scrollAreaRef.current.scrollWidth;
    
            // If the scroll area is wider than the container, set scroll to true
            if (scrollAreaWidth > containerWidth) {
                this.setState({shouldScroll: true});
            }
        }, 1000 * 60); // 1 minute
    }

    handleSetDate = (forceLatest, momentDate) => {
        const {
            LiveDispatchStore: { handleGetDispatches },
        } = this.props;
        const date = moment(momentDate).format('DD-MM-YYYY');

        handleGetDispatches(forceLatest, date);
    };

    handleToggleSwitch = () => {
        const {
            LiveDispatchStore: { setAutoPrint, isAutoPrint },
        } = this.props;
        setAutoPrint(!isAutoPrint);
    };

    handleClickOpenModal = () => {
        const { LiveDispatchStore } = this.props;
        LiveDispatchStore.recentlyClosedAggregatorStores = LiveDispatchStore.closedAggregatorStores;
    }

    render() {
        const {
            UserStore: {
                currentPage,
                handleSetFullscreen,
                isCurrentPageFullScreen,
                selectedHub
            },
            RiderAndOutletPartnerStore
        } = this.props;

        const {
            shouldScroll
        } = this.state;

        const { staffMembers } = RiderAndOutletPartnerStore;

        return (
            <div className={`${base}__wrapper bg--white`}>
                <div className={`${base} display--flex justify--space-between prl`} style={{paddingLeft: '22px'}}> 
                    <div
                        className={`${base}__left display--flex justify--space-between flex-align-items-center`}
                    >
                        <img src={"https://image.popcontent.link/pop_logo.png"} />
                        <p style={{margin: '0px 30px', fontSize: '25px' }}>
                            {selectedHub && `${selectedHub.pickupAddress.name.split('@')[1]}`} 
                        </p>
                        <div className={`${base}__staff_member_container`} ref={this.containerRef}>
                            <div className={`${base}__staff_member_scroll ${shouldScroll ? 'scroll-animation' : ''}`} 
                                ref={this.scrollAreaRef}
                            >
                                {staffMembers.map((staffMember) => (
                                    <p className={`${base}__staff_member`}>
                                        {staffMember.position == "Outlet Manager" && (
                                            <p className={`${base}__outlet_manager`} style={{
                                            }}> C </p>
                                        )}
                                        <p className={`${base}__staff_member_name`}>
                                        <span>
                                            {staffMember.name}
                                        </span>
                                        </p>
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={'display--flex'}>
                        <Link
                            to={{
                                pathname: "/all-orders",
                                state: { from: '/live-dispatch' }
                            }}>
                            <button className={`${base}__view-all-orders mrm`} style={{marginTop: '13px'}}>
                                    View all orders
                                </button>
                        </Link>

                        {(currentPage === 'packing') && (
                            <button
                                className={`${base}__fullscreen_btn`}
                                onClick={() => handleSetFullscreen(!isCurrentPageFullScreen)}
                                style={{marginTop: '13px', height: '54px'}}
                            >
                                <img
                                    className="exit_fullscreen_icon"
                                    src={isCurrentPageFullScreen? "https://image.popcontent.link/exit-full-screen.png" : "https://image.popcontent.link/go_full_screen.png"}
                                    alt="Exit fullscreen"
                                />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default DispatchHeader;

import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { toast, cssTransition } from 'react-toastify';

import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../Modal';
import SKUItem from '../common/SKUItem';
import PrintStickersModal from './PrintStickersModal';
import CustomToastContainer from './CustomToastContainer';
import SalePlatformIcon from "./SalePlatformIcon";
import SalePlatformModalIcon from "../cashierPickup/SalePlatformIcon";

const base = 'live-dispatch';

@inject('LiveDispatchStore', 'ProductionStore')
@observer
class OrderRow extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
        showConfirmationModal: false,
        isPrintItemQRButtonDisabled: false,
        isPrintBagQRButtonDisabled: false,
        showErrorModal: false,
        showOrderDetailModal: false,
        errorMessage: null
    };

    componentDidMount = () => {
        const { order } = this.props;
        const now = moment((order.pick_up_type == "DINE_IN" || order.pick_up_type == "TAKE_AWAY") ? 
            (order.production_started_at ? order.production_started_at : order.order_date) : order.production_started_at, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
        if (duration >= FIVE_MINUTES_IN_MILLISECONDS) {
            this.setState({ delay: true });
        }
        this.setCountdown();
        this.calculateGroupedItems(order.items);
    };

    componentDidUpdate = (prevProps) => {
        const { order } = this.props;
        if (order.order_date !== prevProps.order.order_date) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    resetCountdown = () => {
        if (this.interval) clearInterval(this.interval);
        this.setState({
            hours: '--',
            minutes: '--',
            seconds: '--',
            delay: false,
        });
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };

    countdown = () => {
        const { order } = this.props;
        const now = moment((order.pick_up_type == "DINE_IN" || order.pick_up_type == "TAKE_AWAY") ? 
            (order.production_started_at ? order.production_started_at : order.order_date) : order.production_started_at, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (
            parseInt(minutes) > 4 &&
            parseInt(seconds) > 0 &&
            !this.state.delay
        ) {
            this.setState({ hours, minutes, seconds, delay: true });
        } else {
            this.setState({ hours, minutes, seconds });
        }
    };

    handlePrintIconClick = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleModalState = (state) => {
        this.setState({ showConfirmationModal: state });
    };

    handleOrderDetailClick = () => {
        this.setState({ showOrderDetailModal: true });
    };

    handleOrderDetailModalState = (state) => {
        this.setState({ showOrderDetailModal: state });
    };

    handlePrintBtnState = (btnName, btnState, invertState) => {
        this.setState({ [btnName]: btnState });
        if (invertState) {
            setTimeout(() => {
                this.setState({ [btnName]: !btnState });
            }, 5 * 1000);
        }
    };

    errorCallBack = (response) => {
        this.setState({ showOrderDetailModal: false });
        const { order, LiveDispatchStore } = this.props;
        if (response === 'SUCCESS') {
            if (order.order_mode == "DELIVERY") {
                LiveDispatchStore.handleGetDispatches(true);
            } else {
                LiveDispatchStore.handleGetDineInOrders(true);
            }
        } else {
            this.setState({ showErrorModal: true, errorMessage: response });
        }
    };

    handleMarkOrderAsDone = (orderId) => {
        const { LiveDispatchStore } = this.props;
        LiveDispatchStore.handleMarkOrderAsDone(orderId, this.errorCallBack);
    };

    sendToastNotification = () => {
        const { order } = this.props;
        const slide = cssTransition({
            enter: 'animate__animated animate__slideInDown',
            exit: 'animate__animated animate__slideOutUp',
        });
        //send toast
        toast.configure();
        toast(<CustomToastContainer />, {
            toastId: order.order_number,
            transition: slide,
            closeButton: this.closeButton,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            closeOnClick: false,
        });
    };


    closeButton = () => {
        return (
            <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => toast.dismiss()}
            >
                <rect
                    x="1.06055"
                    width="19.7"
                    height="1.5"
                    transform="rotate(45 1.06055 0)"
                    fill="white"
                />

                <rect
                    width="19.7"
                    height="1.5"
                    transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.9302 0)"
                    fill="white"
                />
            </svg>
        );
    };

    calculateGroupedItems = (items) => {
        const groupedItems = items.reduce((acc, item) => {
            let existingItem = acc.find(i => i.meal_name === item.meal_name);
            item.quantity = 1;
            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                acc.push({ ...item });
            }
            return acc;
        }, []);
        
        this.setState({ groupedItems });
    };

    render() {
        const {
            showConfirmationModal,
            hours,
            minutes,
            seconds,
            delay,
            isPrintItemQRButtonDisabled,
            isPrintBagQRButtonDisabled,
            showOrderDetailModal,
            showErrorModal,
            groupedItems,
            errorMessage
        } = this.state;
        const { order } = this.props;
        const meals = order.items.filter((item) => item.item_type === 'Item' && item.sku != 'Cutlery');
        const cutlery = order.items.filter((item) => item.item_type === 'Item' && item.sku == 'Cutlery');
        const addons = order.items.filter((item) => item.item_type === 'Addons' && item.category_name != 'Drinks');
        const drinks = order.items.filter((item) => item.item_type === 'Addons' && item.category_name == 'Drinks');
        const customerName = order.customer_name || '';
        const customerNameToShow =
        customerName.length > 18
            ? customerName.slice(0, 15) + '...'
            : customerName;
        const referenceNumber = order.table_number ? order.table_number : order.pick_up_number;
        let orderNumberToShow = order.pick_up_type
            ? referenceNumber
            : order.bag_number;
        let orderInfoToShow = (orderNumberToShow ? orderNumberToShow : "") + " " + customerName;
        orderInfoToShow = orderInfoToShow.length > 18 ? orderInfoToShow.slice(0,15) + "..." : orderInfoToShow;

        let isAggregatorPlatform = false;
        if (order.sale_platform &&
            (order.sale_platform.includes('GrabFood')
            || order.sale_platform.includes('FoodPanda')
            || order.sale_platform.includes('ShopeeFood'))) {
            isAggregatorPlatform = true;
        }

        let brandName = null;
        if (order.sale_platform) {
            if (order.sale_platform.includes('GrabFood')) {
                if (order.sale_platform.indexOf(' - GrabFood') > -1) {
                    brandName = order.sale_platform.split(' - GrabFood')[0];
                }
            } else if (order.sale_platform.includes('FoodPanda')) {
                if (order.sale_platform.indexOf(' - FoodPanda') > -1) {
                    brandName = order.sale_platform.split(' - FoodPanda')[0];
                }
            } else if (order.sale_platform.includes('ShopeeFood')) {
                if (order.sale_platform.indexOf(' - ShopeeFood') > -1) {
                    brandName = order.sale_platform.split(' - ShopeeFood')[0];
                }
            }
        }


        const isPaymentRequired = order.transaction_status === 'Pending';
        const cutleryCount = order.cutlery_count;

        return (
            <Fragment>
                <div className={`${base}__dine-in-order ${delay ? 'delay' : ''}`}>
                    <div className={`${base}__bag-header display--flex justify--space-between ${delay ? 'delay' : ''}`}
                        style={{cursor: 'pointer'}}
                        onClick={this.handleOrderDetailClick}>
                        <SalePlatformIcon
                            key={order.order_number}
                            order={order}
                        />

                        <div className="order-info-container">
                            <div className="icon-number-name">                                
                                {isAggregatorPlatform && (
                                    <div className="number-name">
                                        <p className="aggregator_short_order_id">
                                            {customerNameToShow}
                                        </p>
                                    </div>)}
                                {!isAggregatorPlatform && (
                                    <div className="number-name display--flex">
                                        <p className="pick_up_number">
                                            {orderInfoToShow}
                                        </p>
                                    </div>)}
                            </div>
                            <div className={`row ${cutleryCount > 0 && isPaymentRequired ? '' : 'col-xs-12'}`}
                                style={cutleryCount > 0 ? {marginLeft: 0} : {}}>
                                {isPaymentRequired && (
                                    <div className={`order-amount-due`}>
                                        RM {(order.amount_due * 1.06).toFixed(2)} due
                                    </div>
                                )}


                            </div>
                        </div>
                        <div
                            className={`order-time-box ${delay ? 'delay' : ''}`}
                        >
                            <p className="order-time">
                                {`${
                                    parseInt(hours) > 0 ? `${hours}:` : ``
                                }${minutes}:${seconds}`}
                            </p>
                            {order.pick_up_type != 'DINE_IN' ?
                                <button
                                className={`${base}__print btn bg--white`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.handlePrintIconClick(e);
                                }}
                                >
                                    <svg className="icon-print-medium">
                                        <use xlinkHref={`#icon-print-medium`} />
                                    </svg>
                                </button> : ''
                            }
                        </div>
                    </div>
                    
                    <div className="order-items-skus">
                        {brandName ? <div className={`sku-item__item`} style={{ justifyContent: 'center', background: '#4154FF', color: 'white', textAlign: 'center' }}>
                            <p className="aggregator_name">
                                {brandName.toUpperCase()}
                            </p>
                        </div> : null}
                        {meals &&
                            meals.length > 0 &&
                            meals.map((item, index) => (
                                <SKUItem
                                    item={item}
                                    type={'meals'}
                                    isFirstItem={index == 0}
                                    isLastItem={index == meals.length - 1}
                                    isLastCategory={addons.length == 0 && drinks.length == 0 && cutlery.length == 0}
                                    key={`${item.sku}_${item.incremental_number}`}
                                />
                            ))}
                        {addons &&
                            addons.length > 0 &&
                            addons.map((item, index) => (
                                <SKUItem
                                    item={item}
                                    type={'addons'}
                                    isFirstItem={index == 0}
                                    isLastItem={index == addons.length - 1}
                                    isLastCategory={drinks.length == 0 && cutlery.length == 0}
                                    key={`${item.sku}_${item.incremental_number}`}
                                />
                            ))}
                        {drinks &&
                            drinks.length > 0 &&
                            drinks.map((item, index) => (
                                <SKUItem
                                    item={item}
                                    type={'drinks'}
                                    isFirstItem={index == 0}
                                    isLastItem={index == drinks.length - 1}
                                    isLastCategory={cutlery.length == 0}
                                    key={`${item.sku}_${item.incremental_number}`}
                                />
                            ))}
                        {cutlery &&
                            cutlery.length > 0 &&
                            cutlery.map((item, index) => (
                                <SKUItem
                                    item={item}
                                    type={'cutlery'}
                                    isFirstItem={index == 0}
                                    isLastItem={index == cutlery.length - 1}
                                    isLastCategory={true}
                                    key={`${item.sku}_${item.incremental_number}`}
                                />
                            ))}
                    </div>
                </div>
                {showConfirmationModal && (
                    <Modal
                        customStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        width={'45%'}
                        height={'56%'}
                        padding="0px"
                        borderRadius="0px"
                    >
                        <PrintStickersModal
                            orderOrBag={order}
                            handleModalState={this.handleModalState}
                            sendToastNotification={this.sendToastNotification}
                            isPrintItemQRButtonDisabled={isPrintItemQRButtonDisabled}
                            isPrintBagQRButtonDisabled={isPrintBagQRButtonDisabled}
                            handlePrintBtnState={this.handlePrintBtnState}
                        />
                    </Modal>
                )}
                {showOrderDetailModal && (
                    <Modal height={'80vh'} onBackdropClicked={() => this.setState({showOrderDetailModal : false})}>
                    <div className={`${base}__modal_table_title display--flex`}>
                        <div className='display--flex'>
                            <SalePlatformModalIcon
                                key={order.order_number}
                                order={order}
                                isModal={true}
                                isLiveDispatchScreen={true}
                            />
                            <div style={{marginLeft: "24px"}}>
                                Order Number: {order.order_number}
                            </div>
                        </div>
                        <div className={`row ${base}__modal_button_row`} style={{textAlign: "center"}}>
                            <button
                                className={`${base}__submit_button`}
                                onClick={() => this.handleMarkOrderAsDone(order.order_number)}
                            >
                                Mark Done
                            </button>
                        </div>
                        </div>
                        <hr />
                        <div className={`row ${base}__modal_table_body`}>
                            <div className={`row`}>
                                <div className='col-xs-4 text--gray'>
                                    Order Time
                                </div>
                                <div className='col-xs-8' style={(order.order_mode == "DELIVERY" && (order.order_status == "Pending" || order.order_status == "Unconfirmed"))
                                || (order.order_mode == "DINE_IN" && order.order_status != "Delivered")
                                || (order.order_mode == "PICK_UP" && (!order.bag_scanned_at && order.order_status != "Delivered" && order.order_status != "Intransit"))
                                || (order.order_status == "Cancelled") ? {color: 'red'} : {}}>
                                    {new Date(order.created_date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                                </div>
                            </div>
                            <div className={`row ${base}__modal_table_row`}>
                                <div className='col-xs-4 text--gray'>
                                    Bag/Table Number
                                </div>
                                <div className='col-xs-8'>
                                {order.aggregator_order_id ? order.aggregator_order_id : 
                                    (order.table_number ? ("TABLE " + order.table_number) : ("BAG " + order.bag_number ? order.bag_number : "-"))}
                                </div>
                            </div>
                            {
                                (order.sale_platform && order.sale_platform.includes("-")) && (
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Aggregator
                                        </div>
                                        <div className='col-xs-8'>
                                            <AggregatorIcon 
                                                key={order.order_number}
                                                order={order}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div className={`row ${base}__modal_table_row`}>
                                <div className='col-xs-4 text--gray'>
                                    Customer Name
                                </div>
                                <div className='col-xs-8 text--uppercase'>
                                {order.customer_name}
                                </div>
                            </div>
                            <div className={`row ${base}__modal_table_row`} style={{marginBottom: "32px"}}>
                                <div className='col-xs-4 text--gray'>
                                    Customer Phone Number
                                </div>
                                <div className='col-xs-8'>
                                    {order.phone_number}
                                </div>
                            </div>
                            {groupedItems ? groupedItems.map((item, index) => (
                                <div className={`row`}>
                                    <div className='col-xs-4 text--gray'>
                                        {index == 0 ? 'Items' : ''}
                                    </div>
                                    <div className='col-xs-8'>
                                        {item.quantity} x {item.meal_name}
                                    </div>
                                </div>
                            )) : ""}
                            <div className={`row ${base}__modal_table_row`}>
                                <div className='col-xs-4 text--gray'>
                                    Status
                                </div>
                                <div className='col-xs-8' style={(order.order_mode == "DELIVERY" && (order.order_status == "Pending" || order.order_status == "Unconfirmed"))
                                || (order.order_mode == "DINE_IN" && order.order_status != "Delivered")
                                || (order.order_mode == "PICK_UP" && (!order.bag_scanned_at && order.order_status != "Delivered" && order.order_status != "Intransit"))
                                || (order.order_status == "Cancelled") ? {color: 'red'} : {color: '#03C875'}}>
                                    {order.order_status ? order.order_status.toUpperCase() : ""}
                                </div>
                            </div>
                            <div className={`row ${base}__modal_table_row`}>
                                <div className='col-xs-4 text--gray'>
                                    Payment Method
                                </div>
                                <div className='col-xs-8'>
                                    {order.payment_method}
                                </div>
                            </div>
                            <div className={`row ${base}__modal_table_row`}>
                                <div className='col-xs-4 text--gray'>
                                    Payment Done?
                                </div>
                                <div className='col-xs-8'>
                                <img src={(order.transaction_status === "Complete" || (order.payment_method === "Cash" && order.order_status === "Delivered")) ? 
                                    'https://image.popcontent.link/green_checkmark.svg' :
                                                'https://image.popcontent.link/red_cross.svg'} />
                                </div>
                            </div>
                            <div className={`row ${base}__modal_table_row`}>
                                <div className='col-xs-4 text--gray'>
                                    Net Total
                                </div>
                                <div className='col-xs-8'>
                                    {order.net_total ? order.net_total.toFixed(2) : "" }
                                </div>
                            </div>
                            <div className={`row ${base}__modal_table_row`}>
                                <div className='col-xs-4 text--gray'>
                                    Rider Name
                                </div>
                                <div className='col-xs-8'>
                                    {order.rider_name ? order.rider_name : "" }
                                </div>
                            </div>
                            <div className={`row ${base}__modal_table_row`}>
                                <div className='col-xs-4 text--gray'>
                                    Rider Phone Number
                                </div>
                                <div className='col-xs-8'>
                                    {order.rider_phone_no ? order.rider_phone_no : "" }
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}

                {showErrorModal && (
                    <Modal height={'10vh'} onBackdropClicked={() => this.setState({showErrorModal : false})}>
                        <div className={`${base}__modal_table_title`} 
                        style={{justifyContent: "center", height: "100%"}}>
                            <h4 className={`print_text`}>{errorMessage}</h4>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default OrderRow;
